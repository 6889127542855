import useAmmRewardsQuery from '@/composables/queries/useAmmRewardsQuery';
import symbolKeys from '@/constants/symbol.keys';
import { isSameAddress } from '@xclabs/balancer-sdk';
import { InjectionKey, provide } from 'vue';
import { safeInject } from '../inject';

interface RewardContractInfo {
  contractAddress: `0x${string}`;
  totalAllocPoint: string;
  rewardTokenPerSecond: string;
  rewardToken: {
    address: `0x${string}`;
    symbol: string;
    decimals: number;
  };
}

interface PoolInfo {
  address: `0x${string}`;
  allocPoint: string;
  poolId: number;
  totalStaked: string;
}

export interface PoolRewardInfo {
  rewardInfo: RewardContractInfo;
  poolInfo: PoolInfo;
}

const poolRewardsProvider = () => {
  const ammRewardsQuery = useAmmRewardsQuery();

  const getPoolRewardsInfo = (
    poolAddress: `0x${string}`
  ): PoolRewardInfo | undefined => {
    const ammRewards = ammRewardsQuery.data.value;
    if (!ammRewards) return undefined;

    let rewardInfo: RewardContractInfo | undefined;
    let poolInfo: PoolInfo | undefined;

    for (const rewardData of ammRewards.rewardDatas ?? []) {
      poolInfo = rewardData.poolInfos.find(poolInfo => {
        return isSameAddress(poolInfo.address, poolAddress);
      });
      if (poolInfo) {
        rewardInfo = {
          contractAddress: rewardData.rewardsContract,
          totalAllocPoint: rewardData.totalAllocPoint,
          rewardTokenPerSecond: rewardData.rewardTokenPerSecond,
          rewardToken: rewardData.rewardToken,
        };
        break;
      }
    }

    if (!rewardInfo || !poolInfo) {
      return undefined;
    }

    return { rewardInfo, poolInfo };
  };

  const getUserPoolStakes = (poolAddress: `0x${string}`) => {
    const ammRewards = ammRewardsQuery.data.value;
    if (!ammRewards) return undefined;

    const poolRewardInfo = getPoolRewardsInfo(poolAddress);
    if (!poolRewardInfo) return undefined;

    for (const stake of ammRewards.userStakes ?? []) {
      if (
        isSameAddress(
          stake.rewardsContract,
          poolRewardInfo.rewardInfo.contractAddress
        )
      ) {
        const poolStake = stake.stakes.find(stake => {
          return stake.poolId === poolRewardInfo.poolInfo.poolId;
        });
        if (poolStake) {
          return poolStake;
        }
      }
    }
    return undefined;
  };

  const getUserAllPoolStakes = () => {
    const ammRewards = ammRewardsQuery.data.value;
    if (!ammRewards) return [];

    return ammRewards.userStakes.flatMap(stake => {
      return stake.stakes.map(stake => {
        const poolRewardInfo = getPoolRewardsInfo(
          stake.lpToken as `0x${string}`
        );
        if (!poolRewardInfo) throw new Error('No reward info found for stake');
        return {
          ...stake,
          poolRewardInfo,
        };
      });
    });
  };

  return {
    ammRewardsQuery,
    getPoolRewardsInfo,
    getUserPoolStakes,
    getUserAllPoolStakes,
  };
};

/**
 * Provider setup: response type + symbol.
 */
export type PoolRewardsProviderResponse = ReturnType<
  typeof poolRewardsProvider
>;
export const PoolRewardsProviderSymbol: InjectionKey<PoolRewardsProviderResponse> =
  Symbol(symbolKeys.Providers.PoolRewards);

export function providePoolRewards(): PoolRewardsProviderResponse {
  const _provider = poolRewardsProvider();
  provide(PoolRewardsProviderSymbol, _provider);
  return _provider;
}

export function usePoolRewards(): PoolRewardsProviderResponse {
  return safeInject(PoolRewardsProviderSymbol);
}
