import QUERY_KEYS from '@/constants/queryKeys';
import useWeb3 from '@/services/web3/useWeb3';
import { AddressZero } from '@ethersproject/constants';
import { useQuery } from '@tanstack/vue-query';
import useNetwork from '../useNetwork';

export interface AmmRewardsData {
  rewardDatas: {
    rewardsContract: `0x${string}`; // rewards contract address
    rewardTokenPerSecond: string; // e.g. "964891975308641" (18 decimals) ~ 0.000964891975308641 WBERA/second
    totalAllocPoint: string; // e.g. "10009"
    rewardToken: {
      address: `0x${string}`; // reward token address
      symbol: string; // e.g. "WBERA"
      decimals: number; // e.g. 18
    };
    poolInfos: {
      address: `0x${string}`; // lp token address
      allocPoint: string; // e.g. "8000"
      poolId: number; // e.g. 0, 1, 2, ...
      totalStaked: string; // e.g. "1480275919105010797617632" (18 decimals) ~ 1,480,275.91 LP tokens
    }[];
  }[];
  userStakes: {
    rewardsContract: `0x${string}`;
    stakes: {
      poolId: number;
      lpToken: string;
      stakedAmount: string;
      claimedRewards: string;
    }[];
  }[];
}

const getQuery = (userAddress: `0x${string}` | undefined) => {
  const normalizedAddress = userAddress?.toLowerCase() ?? AddressZero;

  return `
    {
      rewardDatas(where: { isActive: true }) {
        rewardsContract
        rewardTokenPerSecond
        totalAllocPoint
        rewardToken {
          address
          symbol
          decimals
        }
        lps {
          allocPoint
          poolId
          totalStaked
          token {
            address
          }
        }
      }
      users(where: { userAddress: "${normalizedAddress}" }) {
        rewardContracts {
          rewardContract
          userInfo {
            poolId
            stakedAmount
            claimedRewards
          }
        }
      }
    }
  `;
};

export default function useAmmRewardsQuery() {
  const { account } = useWeb3();
  const { networkId, appNetworkConfig } = useNetwork();

  const queryKey = QUERY_KEYS.AmmRewards.All(networkId, account);

  const queryFn = async (): Promise<AmmRewardsData> => {
    const subgraphUrl = appNetworkConfig.subgraphs.ammRewards;
    if (!subgraphUrl) {
      return {
        rewardDatas: [],
        userStakes: [],
      };
    }

    try {
      const response = await fetch(subgraphUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: getQuery(account.value as `0x${string}`),
        }),
      });

      const { data } = await response.json();
      const { rewardDatas, users } = data;

      const rewardDatasProcessed = rewardDatas.map(rewardData => ({
        ...rewardData,
        poolInfos: rewardData.lps.map(lp => ({
          ...lp,
          address: lp.token.address,
        })),
      }));

      const poolIdLpTokenMap = rewardDatas.reduce((acc, rewardData) => {
        acc[rewardData.rewardsContract] = {};
        rewardData.lps.forEach(lp => {
          acc[rewardData.rewardsContract][lp.poolId] = lp.token.address;
        });
        return acc;
      }, {} as { [rewardsContract: `0x${string}`]: Record<number, `0x${string}`> });

      const usersProcessed =
        users.length > 0
          ? users[0].rewardContracts.map(rewardContract => ({
              rewardsContract: rewardContract.rewardContract,
              stakes: rewardContract.userInfo.map(userInfo => ({
                ...userInfo,
                lpToken:
                  poolIdLpTokenMap[rewardContract.rewardContract][
                    userInfo.poolId
                  ],
              })),
            }))
          : [];

      return {
        rewardDatas: rewardDatasProcessed,
        userStakes: usersProcessed,
      };
    } catch (e) {
      console.error('Error fetching ammRewards:', e);
      return {
        rewardDatas: [],
        userStakes: [],
      };
    }
  };

  return useQuery<AmmRewardsData>(queryKey, queryFn, {
    refetchOnWindowFocus: true,
    refetchInterval: 30 * 1000,
  });
}
