import { poolMetadata as getPoolMetadata } from '@/lib/config/metadata';
import { Pool } from '@/services/pool/types';
import { Network } from '@xclabs/balancer-sdk';
import { isPast } from 'date-fns';
import useNetwork from './useNetwork';
import { Protocol } from './useProtocols';
import { toJsTimestamp } from './useTime';

export function usePoints(pool: Pool) {
  const { networkId, networkConfig } = useNetwork();
  const poolMetadata = computed(() => getPoolMetadata(pool.id));

  const NECT_ADDR = networkConfig.tokens.Addresses.NECT;

  const poolPoints = computed(() => {
    const defaultPoints: {
      protocol: Protocol;
      multiple: string;
      description?: string;
      expiryTimestamp?: number;
    }[] = [];

    // NECT Points Campaign: Add 4x Beraborrow Points to all pools with NECT token
    if (
      networkId.value === Network.BERACHAIN &&
      NECT_ADDR &&
      pool.tokenAddresses?.includes(NECT_ADDR.toLowerCase())
    ) {
      defaultPoints.push({
        protocol: Protocol.Beraborrow,
        multiple: '4',
      });
    }

    if (poolMetadata.value?.points) {
      return [...poolMetadata.value.points, ...defaultPoints];
    }

    return defaultPoints;
  });

  const validPoints = computed(() =>
    poolPoints.value?.filter(point => {
      if (!point.expiryTimestamp) return true;
      return !isPast(toJsTimestamp(point.expiryTimestamp));
    })
  );

  const hasPoints = computed(
    () => !!validPoints.value && validPoints.value.length > 0
  );

  return {
    poolPoints,
    hasPoints,
  };
}
