import { differenceInWeeks } from 'date-fns';

import { isStable } from '@/composables/usePoolHelpers';
import { oneSecondInMs } from '@/composables/useTime';
import { bnum } from '@/lib/utils';
import {
  OnchainPoolData,
  Pool,
  PoolToken,
  RawOnchainPoolData,
} from '@/services/pool/types';
import { TokenInfoMap } from '@/types/TokenList';

import { networkId } from '@/composables/useNetwork';
import { usePoints } from '@/composables/usePoints';
import { Protocol } from '@/composables/useProtocols';
import { getBalancerSDK } from '@/dependencies/balancer-sdk';
import { captureBalancerException } from '@/lib/utils/errors';
import { PoolRewardInfo } from '@/providers/local/pool-rewards.provider';
import { configService } from '@/services/config/config.service';
import { AddressZero } from '@ethersproject/constants';
import { AprBreakdown, Pool as SDKPool } from '@xclabs/balancer-sdk';
import { burrifiedPoolType } from './burrifier';
import { OnchainDataFormater } from './decorators/onchain-data.formater';

const BURR = configService.network.tokens.Addresses.BAL;
const LOVE_POINTS_TOKEN =
  configService.network.tokens.Addresses.gBERA ?? AddressZero;
const BURR_MULTIPLIERS = configService.network.pools.Stakable.BurrMultiplier;
const TOTAL_LOVE_POINTS_USD = 300000; // 300k love points for 6 months

export default class PoolService {
  private _poolRewardInfo?: PoolRewardInfo;
  private readonly _poolPoints: {
    protocol: Protocol;
    multiple: string;
    description?: string;
    expiryTimestamp?: number;
  }[] = [];
  private readonly _rewardTokenPrice: number = 10; // default BERA price of $10

  constructor(
    public pool: Pool,
    public poolRewardInfoRef?: Ref<PoolRewardInfo | undefined>,
    priceFor?: (address: string) => number
  ) {
    if (poolRewardInfoRef) {
      this._poolRewardInfo = poolRewardInfoRef.value;
      watch(poolRewardInfoRef, newValue => {
        this._poolRewardInfo = newValue;
      });
    }

    this.format();

    const { poolPoints } = usePoints(this.pool);
    this._poolPoints = poolPoints.value;

    if (priceFor && this._poolRewardInfo?.rewardInfo.rewardToken.address) {
      const marketPrice = priceFor(
        this._poolRewardInfo.rewardInfo.rewardToken.address
      );
      if (marketPrice > 0) this._rewardTokenPrice = marketPrice;
    }
  }

  /**
   * @summary Statically format various pool attributes.
   */
  public format(): Pool {
    this.pool.isNew = this.isNew;
    this.pool.chainId = networkId.value;
    this.formatPoolTokens();
    this.burrifyPoolType();
    return this.pool;
  }

  burrifyPoolType = () => {
    this.pool.burrifiedPoolType = burrifiedPoolType(this.pool.poolType);
  };

  public get bptPrice(): string {
    return bnum(this.pool.totalLiquidity).div(this.pool.totalShares).toString();
  }

  /**
   * @summary Calculates and sets total liquidity of pool.
   */
  public async setTotalLiquidity(): Promise<string> {
    let totalLiquidity = this.pool.totalLiquidity;

    try {
      const sdkTotalLiquidity = await getBalancerSDK().pools.liquidity(
        this.pool as unknown as SDKPool
      );
      // if totalLiquidity can be computed from coingecko prices, use that
      // else, use the value retrieved from the subgraph
      if (bnum(totalLiquidity).gt(0)) {
        totalLiquidity = sdkTotalLiquidity;
      }
    } catch (error) {
      captureBalancerException({ error });
      console.error(`Failed to calc liquidity for: ${this.pool.id}`, error);
    }

    return (this.pool.totalLiquidity = totalLiquidity);
  }

  /**
   * @summary Calculates APRs for pool.
   */
  public async setAPR(): Promise<AprBreakdown> {
    let apr = this.pool.apr;

    try {
      const sdkApr = await getBalancerSDK().pools.apr(this.pool);
      if (sdkApr) apr = sdkApr;
      apr = this.setPointsApr(apr);
      apr = this.setAmmRewardsApr(apr);
    } catch (error) {
      captureBalancerException({ error });
      console.error(`Failed to calc APR for: ${this.pool.id}`, error);
    }

    return (this.pool.apr = apr as AprBreakdown);
  }

  formatPoolTokens(): PoolToken[] {
    if (isStable(this.pool.poolType)) return this.pool.tokens;

    return (this.pool.tokens = this.pool.tokens.sort(
      (a, b) => parseFloat(b.weight || '0') - parseFloat(a.weight || '0')
    ));
  }

  public setFeesSnapshot(poolSnapshot: Pool | undefined): string {
    let snapshotFees = '0';
    if (poolSnapshot) snapshotFees = poolSnapshot.totalSwapFee || '0';

    const feesSnapshot = bnum(this.pool.totalSwapFee || 0)
      .minus(snapshotFees)
      .toString();

    return (this.pool.feesSnapshot = feesSnapshot);
  }

  public setVolumeSnapshot(poolSnapshot: Pool | undefined): string {
    let snapshotVolume = '0';
    if (poolSnapshot) snapshotVolume = poolSnapshot.totalSwapVolume || '0';

    const volumeSnapshot = bnum(this.pool.totalSwapVolume || 0)
      .minus(snapshotVolume)
      .toString();

    return (this.pool.volumeSnapshot = volumeSnapshot);
  }

  public setOnchainData(
    rawOnchainData: RawOnchainPoolData,
    tokenMeta: TokenInfoMap
  ): OnchainPoolData | undefined {
    try {
      const onchainData = new OnchainDataFormater(
        this.pool,
        rawOnchainData,
        tokenMeta
      );
      this.pool.isInRecoveryMode = rawOnchainData.isInRecoveryMode;
      this.pool.isPaused = rawOnchainData.isPaused;
      return (this.pool.onchain = onchainData.format());
    } catch (e) {
      console.warn(e);
    }
  }

  public get isNew(): boolean {
    if (!this.pool.createTime) return false;

    return (
      differenceInWeeks(Date.now(), this.pool.createTime * oneSecondInMs) < 1
    );
  }

  private setPointsApr(apr: AprBreakdown | undefined) {
    if (!apr) return apr;

    const hasLovePoints = this._poolPoints.some(
      p => p.protocol === Protocol.Smilee
    );
    if (!hasLovePoints) return apr;

    const rewardsInfo = this._getPoolRewardsInfo();
    if (!rewardsInfo) return apr;
    const { totalStakedUSD } = rewardsInfo;

    const lovePointsAnnualRewardsUSD = bnum(TOTAL_LOVE_POINTS_USD * 2);
    const lovePointsStakingApr = totalStakedUSD.gt(0)
      ? lovePointsAnnualRewardsUSD.multipliedBy(10000).dividedBy(totalStakedUSD)
      : bnum(0);

    return {
      ...apr,
      rewardAprs: {
        total: lovePointsStakingApr.toNumber(),
        breakdown: {
          [LOVE_POINTS_TOKEN]: lovePointsStakingApr.toNumber(),
        },
      },
      min: apr.min + lovePointsStakingApr.toNumber(),
      max: apr.max + lovePointsStakingApr.toNumber(),
    } as AprBreakdown;
  }

  private setAmmRewardsApr(apr: AprBreakdown | undefined) {
    if (!apr) return apr;

    const rewardsInfo = this._getPoolRewardsInfo();
    if (!rewardsInfo) return apr;
    const { poolAllocPercent, totalStakedUSD, burrMultiplier, rewardToken } =
      rewardsInfo;

    // Calculate annual rewards value in USD (monthly * 12 months * BERA price)
    const yearlyEmissionRate = bnum(
      rewardsInfo.rewardTokenPerSecond
    ).multipliedBy(60 * 60 * 24 * 365);
    const annualRewardsUSD = yearlyEmissionRate
      .multipliedBy(poolAllocPercent)
      .multipliedBy(this._rewardTokenPrice)
      .div(1e18);

    // Calculate APR
    const mainStakingApr = totalStakedUSD.gt(0)
      ? annualRewardsUSD.multipliedBy(10000).dividedBy(totalStakedUSD)
      : bnum(0);

    const burrStakingApr = mainStakingApr.multipliedBy(burrMultiplier);

    const breakdownTotalApr = Object.values(
      apr.rewardAprs?.breakdown || {}
    ).reduce((acc, curr) => acc + curr, 0);

    console.table({
      pool: this.pool.name,
      totalStaked: this._poolRewardInfo?.poolInfo.totalStaked,
      bptPrice: this.bptPrice,
      totalStakedUSD: totalStakedUSD.toString(),
      yearlyEmissionRate: yearlyEmissionRate.div(1e18).toString(),
      poolAllocPercent: poolAllocPercent.toString(),
      rewardTokenPrice: this._rewardTokenPrice,
      annualRewardsUSD: annualRewardsUSD.toString(),
      stakingApr: mainStakingApr.toString(),
    });

    // Add staking APR to the APR breakdown
    return {
      ...apr,
      rewardAprs: {
        total:
          breakdownTotalApr +
          mainStakingApr.toNumber() +
          burrStakingApr.toNumber(),
        breakdown: {
          ...apr.rewardAprs?.breakdown,
          [rewardToken.address]: mainStakingApr.toNumber(),
          [BURR]: burrStakingApr.toNumber(),
        },
      },
      min: apr.min + mainStakingApr.toNumber() + burrStakingApr.toNumber(),
      max: apr.max + mainStakingApr.toNumber() + burrStakingApr.toNumber(),
    } as AprBreakdown;
  }

  private _getPoolRewardsInfo() {
    const { rewardInfo, poolInfo } = this._poolRewardInfo ?? {};
    if (!poolInfo || !rewardInfo) return undefined;

    const burrMultiplier = BURR_MULTIPLIERS?.[this.pool.id] ?? 1;

    // Calculate reward allocation for this pool
    const poolAllocPercent = bnum(poolInfo.allocPoint).dividedBy(
      bnum(rewardInfo.totalAllocPoint)
    );

    // Calculate total staked value in USD
    const totalStakedUSD = bnum(poolInfo.totalStaked) // Convert to string without scientific notation
      .div(1e18) // Convert from wei to ether
      .multipliedBy(this.bptPrice);

    return {
      poolAllocPercent,
      totalStakedUSD,
      burrMultiplier,
      rewardTokenPerSecond: rewardInfo.rewardTokenPerSecond,
      rewardToken: rewardInfo.rewardToken,
    };
  }
}
